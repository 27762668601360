export default Object.freeze({
  ADMIN_ROLE: 'admin',
  ANALYTICS_URL: process.env.VUE_APP_ANALYTICS_URL,
  API_VERSION: '/v2.0',
  APP_NAME: 'Actirise platform',
  BOUNCE_PATH: 'Bounce-Path',
  CROSS_JWT: 'cross-jwt',
  CUSTOMER_ROLE: 'customer',
  DARK_LAYOUT: 'dark',
  DEFAULT_LANGUAGE: 'en',
  DOC: process.env.VUE_APP_DOCUMENTATION_URL,
  ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT,
  JWT_EXPIRED_MESSAGE: 'Expired JWT Token',
  LAYOUT_FORM_PAGE: 'form-page',
  LAYOUT_FULL: 'full',
  LAYOUT_HORIZONTAL: 'horizontal',
  LIGHT_LAYOUT: 'light',
  LOCALES: ['en', 'fr'],
  SKIN_STORAGE_KEY: 'vuexy-skin',
  STORAGE_I18N: 'i18n',
  STORAGE_NAME: 'actirise-store',
  SUPPORT_EMAIL: 'support@actirise.com',
  TOKEN_TYPE: 'Bearer'
})
